<template>
  <VariableLaporanManajemenForm mode="Tambah" module="Variable Laporan Manajemen"> </VariableLaporanManajemenForm>
</template>

<script>
import VariableLaporanManajemenForm from './form';

const VariableLaporanManajemenAdd = {
  name: 'VariableLaporanManajemenAdd',
  components: { VariableLaporanManajemenForm },
};

export default VariableLaporanManajemenAdd;
</script>
